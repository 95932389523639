import React, {useEffect, useState} from "react";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Main from "./Main";
import Word from "./Word";
import Login from "./Login";
import Kanji from "./Kanji";
import Register from "./Register";
import MovePage from "./MovePage";
import Button from "./Button";
import Cookies from "js-cookie";
import AlarmIcon from "./AlarmIcon";

const AppContent = () => {
    // const [mode, setMode] = useState('ddark');
    const [mode, setMode] = useState('light');
    const location = useLocation(); // 현재 경로를 확인


    const handleModeChange = (event) => {
        setMode(event.target.value);
    };

    useEffect(() => {
        const savedMode = localStorage.getItem('designMode');
        if (savedMode) {
            setMode(savedMode);
        }
    }, []);

    useEffect(() => {
        localStorage.setItem('designMode', mode);
    }, [mode]);

    const [member, setMember] = useState(null);
    const navigate = useNavigate();

    const fetchMember = async () => {
        try{
            const token = localStorage.getItem('token');
            const csrfToken = Cookies.get('XSRF-TOKEN');

            if(token == null) return;

            const response = await fetch('/api/member/me',{
                method: 'GET',
                credentials: 'include',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'X-CSRF-TOKEN': csrfToken,
                    'Content-Type': 'application/json'
                }
            });

            if(response.ok){
                const data = await response.json();
                setMember(data);
            }else{
                localStorage.removeItem('token');
            }
        }catch(error){
            alert(error.message);
        }
    }

    useEffect(() => {
        fetchMember();
    }, []);

    const handleLogout = () => {
        const csrfToken = Cookies.get('XSRF-TOKEN');

        fetch('/logout', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': csrfToken
            }
        })
            .then(response => {
                if (response.ok) {
                    localStorage.removeItem('token');
                    alert('로그아웃하였습니다.');

                    setMember(null);
                    navigate('/');
                } else {
                    alert('로그아웃에 실패하였습니다.');
                }
            })
            .catch(error => {
                alert('로그아웃에 실패하였습니다.');
            });
    }

    return (
        <div className={`app ${mode}`}>
            <div className="design-mode" style={{display: location.pathname === '/' ? 'block' : 'none'}}>
                <h1 className="design-title">디자인 설정: {mode.toUpperCase()}</h1>
                <select className="select-box" value={mode} onChange={handleModeChange}>
                    {/*<option value="ddark">DDARK</option>*/}
                    <option value="light">LIGHT</option>
                    {/*<option value="dark">DARK</option>*/}
                </select>
            </div>
            <div className="fixed-top-right">
                <AlarmIcon/>
                <div className="inLogin">
                    {!member ? (
                        <div className="login-box">
                            <MovePage path={'/login'} text={'로그인'} classNm={'login-button'}/>
                            <MovePage path={'/register'} text={'회원가입'} classNm={'register-button'}/>
                        </div>
                    ) : (
                        <>
                            <div className="member-message">{member.username}님 안녕하세요!</div>
                            <Button text={'로그아웃'} classNm={'logout-button'} clickEvent={handleLogout}/>
                        </>
                    )}
                </div>
            </div>
            <Routes>
                <Route path="/" element={<Main/>}/>
                <Route path="/word" element={<Word/>}/>
                <Route path="/kanji" element={<Kanji/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/register" element={<Register/>}/>
            </Routes>
        </div>
    );
}

export default AppContent;